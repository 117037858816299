import React from "react"

const TextShadowBox = ({
  componentEC,
  title,
  titleEC,
  description,
  descriptionEC,
}) => {
  return (
    <div
      className={`bg-white rounded-[8px] shadow px-3 py-3 text-center ${componentEC}`}
    >
      <span
        className={`text-[20px] leading-[1.2em] font-ubuntu font-[500] text-secondary block mb-4 ${titleEC}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className={`text-[12px] leading-[1.2em] text-[#25282C] ${descriptionEC}`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

export default TextShadowBox
