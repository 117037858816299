import React, { useState } from "react"
import SectionTitle from "../atoms/SectionTitle"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import ImageRenderer from "../atoms/ImageRenderer"
import BrandParagraph from "../atoms/BrandParagraph"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

const TestimonialsSection = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const imageUrl = ImageUrlRenderer({ img: data.sectionBgImage })

  const nextSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === data.testimonials.length - 1 ? 0 : prevIndex + 1
    )
  }

  const prevSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? data.testimonials.length - 1 : prevIndex - 1
    )
  }

  return (
    <div className="cc-section--vertical-space px-[20px] md:px-0">
      <SectionTitle
        title={data.sectionTitle}
        titleEC={`justify-center`}
        componentEC={`text-center`}
      />
      <div
        className="bg-cover"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: `top`,
        }}
      >
        <div className="bg-primary-light relative max-w-[940px] mx-auto mt-14 rounded-[24px] flex flex-col-reverse md:flex-row items-center justify-between md:gap-[30px]">
          <div className="absolute left-[-15px] md:left-[-50px] bottom-[-20px] md:bottom-[-50px] z-[10] max-w-[75px] md:max-w-[135px]">
            <ImageRenderer
              img={data.sectionDesignImage.path}
              alt={data.sectionDesignImage.alt}
            />
          </div>
          <div className="flex-[2] px-5 md:px-12 py-10">
            <div className="max-w-[30px] mb-5">
              <ImageRenderer
                img={data.sectionArrowImage.path}
                alt={data.sectionArrowImage.alt}
              />
            </div>
            <div>
              <BrandParagraph
                text={data.testimonials[currentIndex].testimonialText}
              />
              <div className="flex flex-row items-center justify-between gap-[10px] mt-8">
                <h4
                  className={`text-[18px] leading-[1.4em] font-[600] text-secondary mb-4`}
                >
                  {data.testimonials[currentIndex].clientName}
                </h4>
                <div className="flex flex-col items-center gap-[5px]">
                  <span className="text-[18px] leading-[1.4em] font-[600] text-secondary">
                    {currentIndex + 1}/{data.testimonials.length}
                  </span>
                  <div className="flex flex-row items-center gap-[10px]">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={prevSlide}
                      className={`cursor-pointer text-secondary ${
                        currentIndex === 0 ? "opacity-40" : "opacity-100"
                      }`}
                    />
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      onClick={nextSlide}
                      className={`cursor-pointer text-secondary ${
                        currentIndex === data.testimonials.length - 1
                          ? "opacity-40"
                          : "opacity-100"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-[1]">
            <div className="max-w-[200px] md:max-w-[100%] mt-5 md:mt-0">
              <ImageRenderer
                img={data.sectionClientImage.path}
                alt={data.sectionClientImage.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSection
