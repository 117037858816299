import React from "react"
import SectionTitle from "../atoms/SectionTitle"
import { blogs } from "../../content/Blogs"
import BlogCard from "../molecules/BlogCard"
import Button from "../atoms/Button"

const BlogsSection = ({ data }) => {
  return (
    <div className="cc-section--horizontal-space cc-section--vertical-space">
      <SectionTitle
        title={data.sectionTitle}
        componentEC={`text-center`}
        titleEC={`justify-center`}
      />
      <div className="flex flex-col md:flex-row gap-[25px] justify-between mt-16">
        <div className="flex-[1]">
          {blogs.slice(0, 1).map((item, index) => (
            <BlogCard
              key={"blog_card_" + index}
              data={item}
              descriptionLength={`450`}
              imageEC={`md:min-h-[360px]`}
            />
          ))}
        </div>
        <div className="flex-[1] flex flex-col gap-[40px] md:gap-[15px]">
          {blogs.slice(1,4).map((item, index) => (
            <BlogCard
              key={"blog_card_" + index}
              data={item}
              componenEC={`md:flex-row`}
              imageEC={`min-w-[180px] lg:min-w-[260px] md:!h-full`}
            />
          ))}
        </div>
      </div>
        <div className="mt-20 text-center">
          <Button
            to={data.btnLink}
            href={data.btnLink}
            text={data.btnText}
            ec={`primary-btn`}
          />
        </div>
    </div>
  )
}

export default BlogsSection
