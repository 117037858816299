import React from "react"
import InlineFormWithEmailField from "../../molecules/InlineFormWithEmailField"
import ImageRenderer from "../../atoms/ImageRenderer"
import ImageUrlRenderer from "../../atoms/ImageUrlRenderer"
import TextShadowBox from "../../atoms/TextShadowBox"
import BrandHeroSectionheading from "../../atoms/BrandHeroSectionheading"

const HeroSection = ({ data }) => {
  const bgImage = ImageUrlRenderer({ img: "home--hero-section-bg" })

  return (
    <div
      className="pt-10 pb-32"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: `cover`,
        backgroundPosition: `bottom`,
      }}
    >
      <div className="cc-section--horizontal-space flex flex-col md:flex-row gap-[50px] md:gap-[20px] items-center justify-between">
        <div className="flex flex-col gap-[40px] max-w-[630px]">
          <BrandHeroSectionheading
            title={data.title}
          />
          <p
            className={`heading-text-highligted-only text-[#25282C] text-[20px] md:text-[22px] leading-[1.4em]`}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          <InlineFormWithEmailField
            formFieldPlaceholderText={data.formFieldPlaceholderText}
            inputFieldEC={`text-[12px]`}
            buttonText={data.formBtnText}
          />
        </div>
        <div className="max-w-[445px] w-full">
          <div className="relative">
            <ImageRenderer
              img={data.sectionMainImage.path}
              alt={data.sectionMainImage.alt}
            />
            <div className="absolute right-[20px] top-[0px] md:top-[30px] max-w-[180px]">
              <ImageRenderer
                img={data.googleReviewsImage.path}
                alt={data.googleReviewsImage.alt}
              />
            </div>
            <div className="absolute right-[35%] md:right-[35%] bottom-[0px] md:bottom-[20px] flex gap-[30px]">
              {data.infoBoxes.map((item, index) => (
                <TextShadowBox 
                    key={`textshadowbox_` + index}
                    componentEC={`max-w-[80px]`}
                    title={item.title}
                    description={item.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
