import React from "react"
import SectionTitle from "../atoms/SectionTitle"
import { properties } from "../../content/Properties"
import PropertyCard from "../molecules/PropertyCard"
import Button from "../atoms/Button"

const PropertiesCardsSection = ({ data }) => {
  return (
    <div className="bg-primary-light mt-28 mb-10 pt-24 pb-24">
      <div className="cc-section--horizontal-space">
        <SectionTitle
          componentEC={`text-center`}
          title={data.sectionTitle}
          titleEC={`text-center justify-center`}
        />
        <div className="flex flex-col md:flex-row flex-wrap gap-[30px] justify-center mt-10">
          {properties.slice(0,6).map((item, index) => (
            <PropertyCard
              key={`property_` + index}
              data={item}
              componentEC={`w-[100%] md:w-[47%] lg:w-[31%]`}
            />
          ))}
        </div>
        <div className=" mt-14 text-center">
          <Button
            to={data.btnLink}
            href={data.btnLink}
            text={data.btnText}
            ec={`secondary-btn`}
          />
        </div>
      </div>
    </div>
  )
}

export default PropertiesCardsSection
