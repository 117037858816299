import React from "react"
import SectionTitle from "../atoms/SectionTitle"
import { stories } from "../../content/Stories"
import StoryCard from "../molecules/StoryCard"
import Button from "../atoms/Button"

const StoriesSection = ({ data }) => {
  return (
    <div className="cc-section--horizontal-space cc-section--vertical-space">
      <SectionTitle
        title={data.sectionTitle}
        titleEC={`justify-center`}
        componentEC={`text-center`}
      />
      <div className="flex flex-col md:flex-row flex-wrap gap-[30px] justify-center mt-10">
        {stories.map((item, index) => (
          <StoryCard
            key={`story_` + index}
            data={item}
            componentEC={`w-[100%] md:w-[47%] lg:w-[31%]`}
          />
        ))}
      </div>
      <div className=" mt-14 text-center">
        <Button
          to={data.btnLink}
          href={data.btnLink}
          text={data.btnText}
          ec={`primary-btn`}
        />
      </div>
    </div>
  )
}

export default StoriesSection
