import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import SectionTitle from "../atoms/SectionTitle"
import BrandParagraph from "../atoms/BrandParagraph"
import { Link } from "gatsby"

const ResourcesSection = ({ data }) => {
  return (
    <div className="cc-section--vertical-space">
      <div className="cc-section--horizontal-space">
        <SectionTitle title={data.sectionTitle} />
        <BrandParagraph text={data.description} textEC={`mt-5`} />
        <BrandParagraph
          text={data.extraDescription}
          textEC={`!text-[#858585] mt-10 text-center`}
        />
        <div className="mt-8 flex flex-col md:flex-row gap-[10px] md:gap-[100px] justify-center">
          {data.iconBoxes.map((item, index) => (
            <div
              className="flex-[1] group pt-12 pb-10 max-w-[280px] rounded-[18px] transition duration-300 hover:bg-[#1C5C4F]"
              key={"resourceiconbox_" + index}
            >
              <Link
                to="/resources#our_resources"
                href="/resources#our_resources"
              >
                <div className="text-center max-w-[70px] mx-auto md:max-w-[100%]">
                  <div className="group-hover:hidden">
                    <ImageRenderer
                      img={item.iconImage.path}
                      alt={item.iconImage.alt}
                    />
                  </div>
                  <div className="hidden group-hover:block">
                    <ImageRenderer
                      img={item.iconImageHovered.path}
                      alt={item.iconImageHovered.alt}
                    />
                  </div>
                </div>
                <h4 className="text-[18px] leading-[1.4em] font-[600] text-[#000000] mt-2 md:mt-5 mx-auto text-center transition duration-300 group-hover:text-white">
                  {item.text}
                </h4>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ResourcesSection
