import React from "react"

const BrandHeroSectionheading = ({title, titleEC}) => {
  return (
    <h1
      className={`heading-text-highligted--circle font-[600] text-[#25282C] text-[40px] md:text-[52px] leading-[1.1em] ${titleEC}`}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  )
}

export default BrandHeroSectionheading
